import React from "react";

export const MeteorShower = () => {
	return (
		<>
			<div class="star"></div>
			<div class="meteor-1"></div>
			<div class="meteor-2"></div>
			<div class="meteor-3"></div>
			<div class="meteor-4"></div>
			<div class="meteor-5"></div>
			<div class="meteor-6"></div>
			<div class="meteor-7"></div>
			<div class="meteor-8"></div>
			<div class="meteor-9"></div>
			<div class="meteor-10"></div>
			<div class="meteor-11"></div>
			<div class="meteor-12"></div>
			<div class="meteor-13"></div>
			<div class="meteor-14"></div>
			<div class="meteor-15"></div>
		</>
	);
};
