import React from "react";
import XLogo from "./XLogo";

import "../App.scss";

const ComingSoon = () => {
	const bgStyle = {
		width: "100vw",
		height: "100vh",
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "relative",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	};

	const h1Style = {
		background: "-webkit-linear-gradient(#FEBF91, #FF6C00)",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		marginTop: "0px",
		marginBottom: "0px",
	};

	const pStyle = {
		color: "white",
		marginTop: "10px",
		marginRight: "10px",
	};

	const xStyle = {
		position: "absolute",
		bottom: "20px",
		left: "50%",
		transform: "translateX(-50%)",
		zIndex: 10,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "space-between",
	};
	const divStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		alignContent: "center",
	};

	const buttonStyle = {
		padding: "10px",
		borderRadius: "10px",
		background: "none",
		border: "1px solid #FFBA88",
		color: "#FFBA88",
		fontSize: "15px",
		cursor: "pointer",
		transition: "background-color 0.3s ease",
		onHover: {
			backgroundColor: "#FFBA88",
			color: "white",
		},
	};

	const divButtonStyle = {
		marginTop: "10px",
		marginLeft: "10px",
	};

	const hashtag = "#L2 #BlastEcosystem #MeteorFinance #BlastL2";
	const tweetContent = `I've just seen a meteor passing by. \n\n I'm probably early. \n What are you waiting for? @fi_meteor \n ➡️ meteorfi.xyz ⬅️ ${hashtag}`;

	const tweet = () => {
		const tweetURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
			tweetContent,
		)} `;
		window.open(tweetURL, "_blank");
	};

	return (
		<div style={bgStyle}>
			<div style={divStyle}>
				<h1 style={h1Style}>meteor</h1>
				<div style={divButtonStyle}></div>
			</div>
			<p style={pStyle}>Coming soon...</p>
			<button style={buttonStyle} onClick={tweet}>
				Join the meteor
			</button>

			<div style={xStyle}>
				<XLogo />
			</div>
		</div>
	);
};

export default ComingSoon;
