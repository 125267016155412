import React from "react";
import cometeLogo from "./img/comete.png";

const MeteorLogo = () => {
	const containerStyle = {
		position: "fixed",
		top: "20px",
		left: "20px",
		display: "flex",
		alignItems: "center",
		zIndex: 1000,
	};

	const logoStyle = {
		width: "50px",
		marginLeft: "10px",
	};

	const textStyle = {
		background: "-webkit-linear-gradient(#FEBF91, #FF6C00)",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		fontSize: "35px",
		fontWeight: "600",
	};

	return (
		<div style={containerStyle}>
			<span style={textStyle}>meteor</span>
			<img src={cometeLogo} alt="Comete Logo" style={logoStyle} />
		</div>
	);
};

export default MeteorLogo;
