import React from "react";
import "../App.scss";
import ComingSoon from "./ComingSoon";
import MeteorLogo from "./meteorLogo";
import {MeteorShower} from "./meteorShower";

function Content() {
	return (
		<div>
			<MeteorLogo />
			<MeteorShower />
			<ComingSoon />
		</div>
	);
}

export default Content;
