import React from 'react';

const XLogo = () => {
  const svgStyle = {
    fill: 'url(#grad1)',
    width: '50px',
    height: '50px',
    cursor: 'pointer'
  };

  const handleClick = () => {
    window.open('https://twitter.com/fi_meteor', '_blank');
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      style={svgStyle}
      onClick={handleClick}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#ffba88', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#ffba88', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"/>
    </svg>
  );
};

export default XLogo;
