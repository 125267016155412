import React from "react";
import "./App.scss";
import Content from "./components/Content";

function App() {
	return (
		<div>
			<Content />
		</div>
	);
}

export default App;
